import React from 'react';
import { GoogleMap, LoadScript, Marker} from '@react-google-maps/api';
import axios from 'axios';
import { useState, useEffect } from "react";
import {GoogleMapEndpoint} from '../../../lib/Endpoints'

function MapContainer(props)  {
	const [address, setAddress] = useState("");
	const [street_number, setStreetNumber] = useState("");
	const [lat, setLatitude] = useState("");
	const [state, setAddressState] = useState("");
	const [lng, setLangtitude] = useState("");
	const [route, setRoute] = useState("");
	const [city, setCity] = useState("");
	const [country, setCountry] = useState("");
	const [postal_code, setPostalCode] = useState("");

	const mapStyles = {        
		height: "100vh",
		width: "100%"
	};
		
	const defaultCenter = {
		lat: 43.6532, lng: -79.3832
	}

	const onSelect = (e) => {
		setLatitude(e.latLng.lat());
		setLangtitude(e.latLng.lng());

		getCoordinates(e.latLng.lat(), e.latLng.lng());
	}

	const getCoordinates = (latt, lang) => {
		
		axios.get(GoogleMapEndpoint+'latlng='+latt+', '+lang+'&key=' + process.env.NEXT_PUBLIC_GOOGLE_API).then((response) => {
			let res = response.data;
			if(res.results.length) {
				let address = res.results[0];
				let geometry = address.geometry.location;

				findAddress(address.address_components);
				setLatitude(geometry.lat);
				setLangtitude(geometry.lng);
			}
		},
		(error) => {
			console.log(error);
		});
	}

	const  findAddress = (addressComponents) => {
		var AddresssForm = {
			locality: 'long_name',
			administrative_area_level_1: 'long_name',
			country: 'long_name',
			postal_code: 'short_name',
			street_number: 'short_name',
			route: 'short_name',
		};
		for (var i = 0; i < addressComponents.length; i++) {
			var addressType = addressComponents[i].types[0];
			switch(addressType) {
				case 'street_number':
				setStreetNumber(addressComponents[i][AddresssForm[addressType]]);
				break ;
				case 'route':
				setRoute(addressComponents[i][AddresssForm[addressType]]);
				break ;
				case 'locality':
				setCity(addressComponents[i][AddresssForm[addressType]]);
				break ;
				case 'administrative_area_level_1':
				setAddressState(addressComponents[i][AddresssForm[addressType]]);
				break ;
				case 'country':
				setCountry(addressComponents[i][AddresssForm[addressType]]);
				break ;
				case 'postal_code':
				setPostalCode(addressComponents[i][AddresssForm[addressType]]);
				break ;
				default:
			}
		}
		let address = '';
		if(street_number) {
			address += street_number +' '
		}

		if(route) {
			address += route + ', '
		}

		if(city) {
			address += city + ', '
		}

		if(state) {
			address += state +', '
		}


		if(postal_code) {
			address += postal_code +', '
		}

		if(country) {
			address += country
		}
		
		let params = {
			'street_number': street_number,	
			'address': address,	
			'city': city,	
			'state': state,	
			'postal_code': postal_code,	
			'country': country,	
		}
		setAddress(address);
		props.handler(params);
	}

	return (
		<LoadScript	googleMapsApiKey={process.env.NEXT_PUBLIC_GOOGLE_API}>
			<GoogleMap	mapContainerStyle={mapStyles} zoom={13}	center={defaultCenter} onClick={(e) => onSelect(e)}>
			</GoogleMap>
		</LoadScript>
	)
}

export default MapContainer;
