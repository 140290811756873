
import Link from 'next/link';
import {  List ,ListInlineItem} from 'reactstrap';
import { useState, useEffect } from "react";
import Image from 'next/image';
import Slider from "react-slick";
import {PublicTechnology} from '../../lib/Endpoints';
import Skeleton,  { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import DefaultImage from '../../public/public/preview-image.jpg';
const images = require.context('../../public/public/sub_categories', true);

export default function App(props) {

	const [data, setData] = useState([]);
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1440,
                settings: { slidesToShow: 6, infinite: true,slidesToScroll: 6 }
            },
            {
                breakpoint: 1366,
                settings: { slidesToShow: 5,slidesToScroll: 5, infinite: true }
            },
            {
                breakpoint: 1024,
                settings: { slidesToShow: 4,slidesToScroll: 4, infinite: true  }
            }, 
            {
            breakpoint: 768,
            settings: { slidesToShow: 2,slidesToScroll: 2, infinite: true }
            }, 
            {
              breakpoint: 320,
              settings: { slidesToShow: 1,slidesToScroll: 1, infinite: true  }
            },         
          ]
      };

		useEffect(() => {
			let isPromise = props.technologies instanceof Promise;

			if(isPromise) {
		    	props.technologies.then((res) => {
		    		setData(res);
		    	});
			} else {
				setData(props.technologies);
			}

		}, [props.technologies]);

  	return (
    	<>
    		<div className="technology_listing">
    			<div className="slider_placeholder">
    				{
						!data.length ? 
							<>
								<ListInlineItem>
									<div className="skeleton_box">
										<div className="skeleton_img"
											style={{ backgroundImage: `url(${DefaultImage.src})`}}>
										</div>
									</div>
								</ListInlineItem>
								<ListInlineItem>
									<div className="skeleton_box">
										<div className="skeleton_img"
											style={{ backgroundImage: `url(${DefaultImage.src})`}}>
										</div>
									</div>
								</ListInlineItem>
								<ListInlineItem>
									<div className="skeleton_box">
										<div className="skeleton_img"
											style={{ backgroundImage: `url(${DefaultImage.src})`}}>
										</div>
									</div>
								</ListInlineItem>
								<ListInlineItem>
									<div className="skeleton_box">
										<div className="skeleton_img"
											style={{ backgroundImage: `url(${DefaultImage.src})`}}>
										</div>
									</div>
								</ListInlineItem>
								<ListInlineItem>
									<div className="skeleton_box">
										<div className="skeleton_img"
											style={{ backgroundImage: `url(${DefaultImage.src})`}}>
										</div>
									</div>
								</ListInlineItem>
								<ListInlineItem>
									<div className="skeleton_box">
										<div className="skeleton_img"
											style={{ backgroundImage: `url(${DefaultImage.src})`}}>
										</div>
									</div>
								</ListInlineItem>
								<ListInlineItem>
									<div className="skeleton_box">
										<div className="skeleton_img"
											style={{ backgroundImage: `url(${DefaultImage.src})`}}>
										</div>
									</div>
								</ListInlineItem>
								<ListInlineItem>
									<div className="skeleton_box">
										<div className="skeleton_img"
											style={{ backgroundImage: `url(${DefaultImage.src})`}}>
										</div>
									</div>
								</ListInlineItem>
							</>
						: ''
					}
    			</div>
		    				<Slider {...settings}>
		        {
		           data && data.length === 10 ? data.map((res, index) => {
		               	let image = index + '.jpg';
		            	return (
				                <div key={index}>
		            				<a href={process.env.NEXT_PUBLIC_APP_URL + "/agencies/"+res.attributes.category_slug + '/' + res.attributes.slug + '/'} aria-label={res.attributes.name}>
		            					<div>
			                    			<div className="inner_wrap">             
			                    				<div className="img_section">
			                        				<span>
			                        					<Image src={images(`./${image}`).default}
			                        						className="cat_img"
			                        						alt="cover-img"
			                        						layout='fill' />
			                        				</span>
			                    				</div>
			                    				<div className="tech_name_section">
			                            			<p className="description-text">{res.attributes.name}</p>
			                    				</div>
			                    			</div>
			                			</div>
			                		</a>
		                		</div>
		            	);
		            }) : ''
		        }
		        			</Slider>
    		</div>
		</>
  	)
}
