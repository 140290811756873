import HomeContent from '../components/public/Home.jsx';
import Script from "next/script";
import PublicLayout from '../components/layouts/PublicLayout';
import axios from 'axios';
import Head from 'next/head';
import { useRouter } from 'next/router'
import { useState, useEffect } from 'react';
import cache from "memory-cache";

function Home({meta, categories, populerTechnologies, technologies, blogs}) {
    
    const router = useRouter();
    const [loading, setLoading] = useState(false);

    return (
    <div>
         <Head>
            <meta name="keywords" content={meta ? meta.keywords : ''} />
            <title>{meta ? meta.title : ''}</title>
            <meta name="description" content={meta ? meta.description : ''} />
            <link rel="canonical" href={process.env.NEXT_PUBLIC_APP_URL + router.asPath} />
        </Head>
        <HomeContent 
            categories={categories}
            populerTechnologies={populerTechnologies}
            technologies={technologies}
            blogs={blogs}/>
        <Script id="my-script" async src="https://www.googletagmanager.com/gtag/js?id=UA-234922394-1"></Script>
    </div>
    )
}

export async function getServerSideProps(context) {
    try {
        const base_url = process.env.NEXT_PUBLIC_API_URL;
        let pouplerCategoriesRes = [];
        let populerTechnologiesRes = [];
        let technologiesRes = [];
        let blogsRes = [];

        const metaRes = await axios.get(base_url+'metas?path=/', {
            headers: { "Accept": "application/json" }
        });

        const categoryUrl = base_url+'poupler_categories?is_popular=5&limit=5';
        const propTechUrl = base_url+'all_technologies?is_populer=true&limit=5'
        const allTechUrl = base_url+'all_technologies?limit=10';
        const blogsUrl = base_url+'home_blogs?limit=4';

        pouplerCategoriesRes = cache.get(categoryUrl);

        if (!pouplerCategoriesRes) {
            const cateReq = await axios.get(categoryUrl, {
                headers: { "Accept": "application/json" }
            });

            pouplerCategoriesRes = cateReq.data.data;
            cache.put(categoryUrl, pouplerCategoriesRes, 24 * 1000 * 60 * 60);
        }

        populerTechnologiesRes = cache.get(propTechUrl);

        if (!populerTechnologiesRes) {
            const propTechReq = await axios.get(propTechUrl, {
                headers: { "Accept": "application/json" }
            });

            populerTechnologiesRes = propTechReq.data.data;
            cache.put(propTechUrl, populerTechnologiesRes, 24 * 1000 * 60 * 60);
        }

        technologiesRes = cache.get(allTechUrl);

        if (!technologiesRes) {
            const techReq = await axios.get(allTechUrl, {
                headers: { "Accept": "application/json" }
            });

            technologiesRes = techReq.data.data;
            cache.put(allTechUrl, technologiesRes, 24 * 1000 * 60 * 60);
        }

        //blogsRes = cache.get(blogsUrl);
        blogsRes = null;
        if (!blogsRes) {
            const blogReq = await axios.get(blogsUrl, {
                headers: { "Accept": "application/json" }
            });

            blogsRes = blogReq.data.data;
            cache.put(blogsUrl, blogsRes, 24 * 1000 * 60 * 60);
        }

        return {
            props: {
                meta: metaRes.data.data,
                categories: pouplerCategoriesRes,
                populerTechnologies: populerTechnologiesRes,
                technologies: technologiesRes,
                blogs: blogsRes,
            }
        }

    } catch (err) {
        const categories = false;
        const meta = false;
        const populerTechnologies = false;
        const technologies = false;
        const blogs = false;

        return { props: { meta, categories, populerTechnologies, technologies, blogs } };
    }
}

Home.Layout = PublicLayout;

export default Home;

