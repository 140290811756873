import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Tick from '../../public/public/tick.png';
import Link from 'next/link';
import Image from 'next/image';
import { getCookie } from 'cookies-next';

export default class App extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isOpen:false,
		}

		this.closeModal = this.closeModal.bind(this);
	}

	componentDidMount()
	{
		this.setState({
			isOpen: true,
		});
	}

	closeModal() {
		this.setState({isOpen:false}, () => {
			window.location.reload();
		});
	}

	render() {
		let user = getCookie('token');
		return (
			<>
			<Modal show={this.state.isOpen} className="quote__question_modal thank_you_model">
				<Modal.Header closeButton onClick={this.closeModal}></Modal.Header>
				<Modal.Body>
					<div className="text-center">
						<Image src={Tick} alt="thank you"/>
						<h3 className="main-heading">Thank You!</h3>
						<p className="description-text">Your service request has been sent.</p>
						{ user  ?
						<Link href='/dashboard' >
   							<a className="primary-btn"><span>Go to Dashboard</span></a>
   						</Link> : ''}
					</div>
				</Modal.Body>
			</Modal>
			</>
		);
	}
}