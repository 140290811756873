
import Link from 'next/link';
import {  List ,ListInlineItem} from 'reactstrap';
import { useState, useEffect } from "react";
import Image from 'next/image';
import {PublicBlogs} from '../../lib/Endpoints';
import { Container, Row, Col} from 'reactstrap';
import HomeBlog from '../../public/public/home-blog.png';
import Skeleton,  { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import DefaultImage from '../../public/public/preview-image.jpg';
import parse from 'html-react-parser';

export default function Technologies(props) {

	const [data, setData] = useState([]);

	useEffect(() => {
    	let isPromise = props.blogs instanceof Promise;

    	if(isPromise) {
	    	props.blogs.then((res) => {
	    		setData(res);
	    	});
    	} else {
    		setData(props.blogs);
    	}

    }, [props.blogs]);

	return (
		<>
			<Container fluid="xl">
				<div className="home-blog_section padding-wrap">
					<h4 className="main-heading text-center">Latest Blogs</h4>
					{
						!data.length ? 
							<div className="blog_listing row">
							<ListInlineItem className="col-md-6 col-lg-3">
								<div className="skeleton_box">
									<div className="skeleton_img" style={{ backgroundImage: `url(${DefaultImage.src})`}}></div>
									<div className="skeleton_lines">
									 	<SkeletonTheme color="#202020" highlightColor="#EBEBEB">
											<Skeleton />
											<Skeleton />
										</SkeletonTheme>
									</div>
								</div>
							</ListInlineItem>
							<ListInlineItem className="col-md-6 col-lg-3">
								<div className="skeleton_box">
									<div className="skeleton_img" style={{ backgroundImage: `url(${DefaultImage.src})`}}></div>
									<div className="skeleton_lines">
									 	<SkeletonTheme color="#202020" highlightColor="#EBEBEB">
											<Skeleton />
											<Skeleton />
										</SkeletonTheme>
									</div>
								</div>
							</ListInlineItem>
							<ListInlineItem className="col-md-6 col-lg-3">
								<div className="skeleton_box">
									<div className="skeleton_img" style={{ backgroundImage: `url(${DefaultImage.src})`}}></div>
									<div className="skeleton_lines">
									 	<SkeletonTheme color="#202020" highlightColor="#EBEBEB">
											<Skeleton />
											<Skeleton />
										</SkeletonTheme>
									</div>
								</div>
							</ListInlineItem>
							<ListInlineItem className="col-md-6 col-lg-3">
								<div className="skeleton_box">
									<div className="skeleton_img" style={{ backgroundImage: `url(${DefaultImage.src})`}}></div>
									<div className="skeleton_lines">
									 	<SkeletonTheme color="#202020" highlightColor="#EBEBEB">
											<Skeleton />
											<Skeleton />
										</SkeletonTheme>
									</div>
								</div>
							</ListInlineItem>
						</div>			
						: ''
					}
					<Row className="blog_listing">
						{
							data ? data.map((blog, index) => {
								const description = blog.attributes.description.substring(0, 100);
								const blogDetailUrl = (blog.attributes.slug != null) ? blog.attributes.slug : ''
								return (
									<Col lg="3" md="6" key="1">
										<div className="blogs-home_section" style={{backgroundImage: `url(${HomeBlog})`}}>
											<p className={"b_category "+blog.attributes.keyword.split(' ').join('_')}>
												{blog.attributes.keyword}
											</p>
											<div className="content_section">
												<h6 className="blog_name">{blog.attributes.title}</h6>
												<p className="description-text-sm">{parse(description+'...')}
													<Link href={process.env.NEXT_PUBLIC_APP_URL + "/blogs/" + blogDetailUrl + '/'}>
														<a aria-label={'blogs_'+ index} className="read_more">
															<span>Read More</span>
														</a>
													</Link>
												</p>
											</div>
										</div>
									</Col>
								);
							}) : ''
						}
					</Row>
					<div className="text-center">
						<Link href={process.env.NEXT_PUBLIC_APP_URL + "/blogs/"}>
							<a className="primary-btn"><span>View All</span></a>
						</Link>
					</div>
				</div>
			</Container>
		</>
	);
}
	