import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { UncontrolledTooltip } from "reactstrap";
import Link from 'next/link';
import MapComponent from './MapComponent';

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: '',
			title: '',
			status: false,
			isOpen: false,
			image: null,
			minimumLength: 400,
			idealLength: 300,
			maxLength: 600,
			is_active: '',
			cover_image: '',
			props: '',

		};

		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.handler = this.handler.bind(this);
	}

	openModal = () => {
		this.setState({
			isOpen: true,
		});
	}

	closeModal = () => {
		this.setState({
			isOpen: false,
		});
		this.props.handler(this.state.props);
	}

	handler(state) {
		this.setState({
			props: state,
		})
		this.props.handler(state);
	}

	render() {
		return (
			<>
			<a  onClick={this.openModal} href="#"><i className="fas fa-map-marker-alt"></i></a>
			<Modal show={this.state.isOpen} onHide={this.closeModal} className="find-loc-model">
				<Modal.Header closeButton>
				<Modal.Title>Select Location</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="map-view">
				<MapComponent handler={this.handler}/>
				</div>
			</Modal.Body>
				<Modal.Footer>
				<Button  onClick={this.closeModal} className="primary-btn">
				<span>Close</span> 
				</Button>
			</Modal.Footer>
			</Modal>
			</>
		);
	}
}

export default App;