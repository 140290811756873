import React, { Component } from "react";
import axios from 'axios';
import FlashState from "../../Common/FlashState";
import Select from 'react-select';
import CreateModal from './CreateModal';
import {Input } from 'reactstrap';
import { Form} from "react-bootstrap";
import QuoteFlashMessage from "../../Common/QuoteFlashMessage";
import GoogleModal from '../GoogleMap/GoogleModal'

import {QuoteCategories} from '../../../lib/Endpoints';

export default class List extends Component {

	constructor(props) {
		super(props)
		this.state = {
			services: [],
			isSearchable: true,
			open:false,
			service: '',
			flash_message: '',
			flash_type: '',
			endpoint: QuoteCategories,
			agencyId:null,
			serviceErr: '',
			postalCodeErr: '',
			postalCode:'',
			showFlasMsg:false,
			serviceName: '',
			user: null,
		}

		this.onChangeName = this.onChangeName.bind(this);
		this.errorHandler = this.errorHandler.bind(this);
		this.closeFlashMessage = this.closeFlashMessage.bind(this);
		this.flashHandler = this.flashHandler.bind(this);
		this.onChangePostalCode = this.onChangePostalCode.bind(this);
		this.resetForm = this.resetForm.bind(this);
		this.handler = this.handler.bind(this);
	}

	componentDidMount() {
		axios.get(this.state.endpoint+ '?is_question=true', { 
			headers: {
				"Access-Control-Allow-Origin" : "*",
				'Content-Type': 'application/json',
				"Access-Control-Allow-Headers": "Origin, Content-Type, Accept, Authorization, X-Request-With",
				"Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
				"Accept": "application/json" 
			}

		}).then(res => {
			this.setState({ services: res.data.data });

		},(error) => {
			console.log(error.response); 
		});
	}

	ServicesList() {
		return (this.state.services.map(data =>({label:data.attributes.name,value:data.id})))
	}

	onChangeName(e) {
		if(e && e.value) {
			this.setState({service: e.value, serviceErr: '', serviceName:e.label});
		}
	}

	onChangePostalCode(e) {
		this.setState({postalCode: e.target.value, postalCodeErr: ''});
	}

	errorHandler(key, value) {
		switch(key) {
			case 'postal_code':
			this.setState({postalCodeErr: value});
			break;
			case 'service_id':
			this.setState({serviceErr: value});
			break
			default:
			break;
		}
	}

	resetForm() {
		this.setState({
			service: '',
			agencyId: '',
			postalCode: '',
		});
		setTimeout(() => {
			this.setState({
				serviceErr: '',
				postalCodeErr: ''
			})
		}, 20);
	}

	flashHandler() {
		this.setState({
			flash_message: FlashState.get('message'),
			flash_type: FlashState.get('type'),
			postalCode: '',
			service: '',
			showFlasMsg: true,
		});

	}

	closeFlashMessage() {
		this.setState({ flash_message: '', flash_type: '' });
	}

	showFlashPopup()
	{
		if(this.state.showFlasMsg) {
			return (<QuoteFlashMessage/>);
		}
	}


	handler(data) {
		if(data.postal_code) {
			this.setState({
				postalCode:data.postal_code,
				postalCodeErr: '',
			})
		}

	}

	render() {
		const mystyle = {
			option: (provided, state) => ({
				...provided,
				padding: '8px 15px',
				backgroundColor: '#fff !important',
				color: '#000',
				fontSize: '14px',
			}),
			control: () => ({
				border: '1px solid #A4A4A4 ',
				color: '#000',
				fontSize: '14px',
				padding: '7px 15px',
				backgroundColor: '#fff !important',
				width: '100%',
				borderRadius: '5px',
				paddingRight: '34px',
				borderTopRightRadius: '0px',
				borderBottomRightRadius: '0px',
				height:'51px'
			}),
			placeholder:() =>({
				color: '#969696',
				position: 'absolute',
				left: '11px'
			}),
			indicatorsContainer: () => ({
				position:'absolute',
				right:'0',
				top:'7px',
				color:'#A4A4A4'
			}),
		};

		return (
			<>
			<div className="select_quote_wrapper">
				{this.showFlashPopup()}
				<div className="project_quote__section">
				<Form autoComplete="off">
					<div className="first_field">
						<Select
							name="name"
							isSearchable={this.state.isSearchable}
							options={this.ServicesList()}
							onChange={this.onChangeName} 
							styles={mystyle}
							className="input-text"
							value={this.ServicesList().filter(x => x.value === this.state.service)}
							placeholder="Service"
						/>
						<span className="errorMsg">{this.state.serviceErr}</span>
					</div>
					<div className="first_field">	
						<Input
							type="text"
							name="pin_code"
							placeholder="Postal Code"
							className="input_text"
							value={this.state.postalCode ? this.state.postalCode : ''}
							onChange={this.onChangePostalCode}
							onInput={this.onChangePostalCode}
						/>
						<span className="errorMsg">{this.state.postalCodeErr}
						</span>
						{this.state.postalCodeErr === 'Invalid postal code!' ? <GoogleModal handler={this.handler}/>  : '' }
							{/*<GoogleModal handler={this.handler}/> : ''*/}
					</div>
					</Form>
				</div>
				<CreateModal 
					state={this.state}
					handler={this.errorHandler}
					resetForm={this.resetForm}
					flashHandler={this.flashHandler}
					/>
			</div>
			</>
			);
	}
}
