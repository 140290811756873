import { Container, Row, Col} from 'reactstrap';
import { Nav } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Link from 'next/link';
import Image from 'next/image';
import FindFirm from '../../public/public/find-firm.jpg';
import FindFirmWebp from '../../public/public/find-firm.webp';
import ChooseResultTab from '../../public/public/wall-clock.png';
import HomeHire from '../../public/public/home-hired.png';
import HomeHireWebp from '../../public/public/home-hired.webp';

import Vendor from '../../public/public/vendor.jpg';
import VendorWebp from '../../public/public/vendor.webp';

import HomeAbout from '../../public/public/home-about.png';
import HomeAboutWebp from '../../public/public/home-about.webp';

import ChooseResultTab1 from '../../public/public/choose-tab1.jpg';
import ChooseResultTab1Webp  from '../../public/public/choose-tab1.webp';

import ChooseResultTab2 from '../../public/public/verified-professionals.png';
import ChooseResultTab2Webp from '../../public/public/verified-professionals.webp';

import ChooseResultTab3 from '../../public/public/read-reviews-browse-projects.png';
import ChooseResultTab3webp from '../../public/public/read-reviews-browse-projects.webp';

import ChooseResultTab4 from '../../public/public/compare-quotes.png';
import ChooseResultTab4Webp from '../../public/public/compare-quotes.webp';
import Categories from './HomeCategories.jsx'
import PopulerTechnologies from './PopulerTechnologies.jsx';
import Technologies from './AllTechnologies.jsx';
import HomeBlogs from './HomeBlogs.jsx';
import QuoteListing from './Quote/listing.js';
import DefaultImage from '../../public/public/preview-image.jpg';

function Home(props) {

  return (
        <>
			<div className="find-firm-section" >
				<Container fluid={true} id="send_quote">
					<Row>
						<Col md="6">
							<div className="left__col">
								<h3 className="small-text-title">Toprow</h3>
								<h1 className="main-heading">Find Top Firms For Your Next Big Project</h1>
								<p className="description-text">The Talent Matching Platform for Result-Driven Businesses</p>
								<p className="description-text">Access specialists in your project categories and complete important tasks faster and more efficiently.</p>
									 <QuoteListing/> 
								<p className="description-text">Toprow is home to professionals who blaze the trail by helping businesses get more done.</p>
							</div>
						</Col>
						<Col md="6" className="right__col dashboard_loader">
							<Image src={FindFirm} alt="find firm" className='bg_placeholder' style={{ backgroundImage: `url(${DefaultImage.src})` }} />
						</Col>
					</Row>
				</Container>
			</div>        
			<Container fluid="xl">
				<div className="padding-wrap category_pop_section text-center">
				   <h2 className="main-heading">Browse Project Categories</h2>
			 <Categories categories={props.categories}/>
                <Link href={process.env.NEXT_PUBLIC_APP_URL + "/categories/"} className="primary-btn">
                	<a class="primary-btn" aria-label="browse categories"> <span>View All</span></a>
                </Link>
				</div>
			</Container>  
			<div className="padding-wrap services_pop_section text-center">
				<Container fluid="xl">
				   <h2 className="main-heading">Most Popular Services</h2>
					 <PopulerTechnologies populerTechnologies={props.populerTechnologies}/>
				</Container>  
			</div>
			<div className="vendor-section placeholder_align padding-wrap">
				<Container fluid="xl">
					<Row>
						<Col md={6}>
							<div className="vendor-section-img">
								<Image src={VendorWebp} alt="vendor" layout='fill' className='bg_placeholder' style={{ backgroundImage: `url(${DefaultImage.src})` }} />
							</div>
						</Col>
						<Col md={6}>
						<h2 className="main-heading">Are you a vendor?</h2>
						<p className="description-text">Connect with the right businesses. Get your services listed on Toprow and close more business. Acquire leads and drive traffic to your service business with Toprow. There are various categories you can work from. Create your profile to get started and choose the category that best describes your service.</p>
						<Link href={process.env.NEXT_PUBLIC_APP_URL + "/agency/signup/"} className="primary-btn"><a className='primary-btn' aria-label="get started"> <span>GET STARTED</span></a></Link>
						<div className="second-part">
							<p className="description-text">Vendors on Toprow are making a big impact on businesses around the world.</p>
							<p className="description-text">Learn how you can become a part of the Toprow community.</p>
								<a href={process.env.NEXT_PUBLIC_APP_URL + "/review-guidelines/"}
									className='primary-btn' aria-label="learn-more-about-guidelines" rel="guidelines">
									<span>Learn More</span>
								</a>
						</div>	
						</Col>
					</Row>
				</Container>
			</div>
			<div className="home-technology-section top-spacing">
				<Container fluid="xl">
						<div className="text-center">
							<h2 className="main-heading">Discover More Through Technologies</h2>
							<p className="description-text-sm">We&aposre utilizing the latest technologies and tools to vet talented professionals who offer their services through our platform. Our approach allows you to discover vendors at the top tier of their market. On Toprow, you&aposll gain access to vendors who operate full blown firms in their service areas. Our pool of talent have many years of experience driving efficiencies for their clients. We provide growth opportunities to the professionals on Toprow that empower them to create the best value in the market. Businesses on Toprow are better for it.</p>
							<h2 className="main-heading-sm">  Find A Talent Now</h2>
						 </div>
				</Container>
				<div>
					  <Technologies technologies={props.technologies}/> 
				</div>     
			</div>
			
			<Container fluid="xl">
				<div className="home-choose-section padding-wrap">
					<Tab.Container id="left-tabs-example" defaultActiveKey="tab1">
					<Row>
						<Col lg={6}>
							<div className="tab-col_left">
						<h2 className="main-heading">Why Choose Us?</h2>
						<p className="description-text-sm">Toprow is the platform of the future for finding the right type of talent. Accelerate your productivity by getting an expert to complete your project. Our vendors are handpicked to ensure maximum success and productive collaboration between businesses and skilled professionals.</p>
						<Nav variant="pills" className="flex-column">
							<Nav.Item>
								<Nav.Link eventKey="tab1" href="#">
								   <h5>Verified Professionals</h5>
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="tab2" href="#">
								  <h5>Read Reviews and Browse Projects</h5>
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="tab3" href="#">
									<h5>Compare Quotes</h5>
								</Nav.Link>
							</Nav.Item>
						</Nav>
						</div>
						</Col>
						<Col lg={6}>
						<Tab.Content>
							<Tab.Pane className="tab-inner-wrap" eventKey="tab1" style={{ backgroundImage: `url(${ChooseResultTab2.src})`}} >
							<div className="inner-content">
							<Image src={ChooseResultTab} alt="choose tab" />
							<p className="tab_content_heading">Verified Professionals</p>
							<p className="description-text-sm">At Toprow, we evaluate all vendors yearly to keep track of their performance and ensure they are consistently bringing value to your business. Every vendor is a verified professional who is invested in your project. The vendor does not just complete tasks but ensures that the work delivered achieves your goal. Whether you are designing a new website, fulfilling a marketing campaign or looking to scale your e-commerce business, Toprow has talented professionals that can help you complete your project effectively. We qualify vendors before onboarding them to ensure that whoever you work with is not only skilled but is an industry expert. Some of our vendors are agencies with everything that you need in one place.</p>
						</div>
							</Tab.Pane>
							<Tab.Pane className="tab-inner-wrap" eventKey="tab2" style={{ backgroundImage: `url(${ChooseResultTab3.src})` }} >
							<div className="inner-content">
							<Image src={ChooseResultTab} alt="choose tab" />
							<p className="tab_content_heading">Read Reviews and Browse Projects</p>
							<p className="description-text-sm">Toprow lets you explore and read the reviews of the agencies. Here you can also view their portfolio and projects to know intensely about the leading agencies featured in our listing.</p>
						</div>
							</Tab.Pane>
							<Tab.Pane className="tab-inner-wrap" eventKey="tab3" style={{ backgroundImage: `url(${ChooseResultTab4.src})` }} >
							<div className="inner-content">
							<Image src={ChooseResultTab} alt="choose tab" />
							<p className="tab_content_heading">Compare Quotes</p>
							<p className="description-text-sm">Our platform allows you to evaluate the agency after making a satisfactory comparison of services, quality of work and pricing model. Make a wise and productive choice!</p>
						</div>
							</Tab.Pane>
						</Tab.Content>
						</Col>
					</Row>
					</Tab.Container>
					<div className="top-spacing text-center ready-start-wrap">
						<h4 className="main-heading">Ready to Get Started?</h4>
						<a href="#" className="primary-btn"><span>Learn How It Works</span></a>
					</div>
				</div>
			</Container>
			<div className="home-yellow_section placeholder_align">
				<Container fluid="xl">
					<div className="top-spacing">    
						<Row>
							<Col md="6">
								<div className="top-spacing-img">    
									<Image src={HomeHire} alt="hire" className="shadow-image bg_placeholder" layout='fill' style={{ backgroundImage: `url(${DefaultImage.src})` }} />
								</div>
							</Col>
							<Col md="6">
								<div className="sec-1-yellow-right">
									<h4 className="main-heading">Hire Verified and Reviewed Pros</h4>
									<p className="description-text-sm">
									Check our pool of verified professionals to get a vendor with the right set of skills and expertise for your next big project. Your project is important to us. That  why our professionals are verified. Toprow is equipped with verified and reviewed pros who can help you complete your tasks and achieve your business goals. 
									</p>
								</div>
							</Col> 
						</Row>
					</div>
					<div className="top-spacing">    
						<div className="sec-1-yellow-right text-center">
							<h4 className="main-heading">Work With Top Businesses</h4>
							<p className="description-text-sm">
							If you are a vendor, you can find a pool of businesses on Toprow who are looking for your skills. Each business has a profile and testimonials to help you determine if they are the right fit for you. You can begin expanding your opportunities and working on innovative projects by collaborating with a business that needs your service.
							</p>
						</div>
					</div>
					<div className="padding-wrap">
						<Row>
							<Col md="6">
								<div className="sec-1-yellow-left">
									<h4 className="main-heading">About Us</h4>
									<p className="description-text-sm">
									Toprow is a solution oriented platform for businesses looking to execute projects that drive business growth. It is a single destination for businesses and vendors to collaborate on projects. The vendors on Toprow are carefully selected to meet the needs of business owners so that projects are completed effectively and efficiently. We are the leading platform for matching businesses and vendors and getting work done faster. The talents on Toprow can help you concentrate on other parts of your business while accomplishing your most important tasks.
									</p>
									<a href={process.env.NEXT_PUBLIC_APP_URL + "/about/"} className="primary-btn"
										aria-label="learn-more-about-toprow" rel="toprow">
										<span>Learn More</span>
									</a>
								</div>
							</Col>
							<Col md="6">
								<div className="text-right">
									<Image src={HomeAbout} layout="fill" alt="hire" className='bg_placeholder' style={{ backgroundImage: `url(${DefaultImage.src})` }}/>
								</div>
							</Col>
						</Row>
					</div>
				</Container>
			</div>
			<Container className="home-blogs" fluid="xl">
				 <HomeBlogs blogs={props.blogs}/> 
			</Container>
			<div className="home-quote-started_section">
				<Container fluid="xl">
					<div className="padding-wrap text-center">    
					<h4 className="main-heading-lg">Get Started Today</h4>
						<p className="description-text-sm">
						Find the right agency for your next big project
						</p>
						<Link href={process.env.NEXT_PUBLIC_APP_URL + "/"}><a className="primary-btn" aria-label="get quote"><span>Get Quote</span></a></Link>
					</div>
				</Container>
			</div>
		</>
	);
  }
  
  export default Home;